<template>
  <div class="pack-box" :class="`pack-box${copyright.entrantsType}`">
    <div class="pack">
      <div class="back" @click.stop="goBack()"><i class="iconfont icon-gengduo" /> 返回试卷包列表</div>
      <div v-if="list && list.length > 0" class="list">
        <div v-for="(item, index) in list" :key="index" class="item" @click="tabId = item.id">
          <img class="item-image" src="@/assets/img/study/pager.png">
          <div class="item-content">
            <div class="item-title ellipsis">{{ item.name }}</div>
            <div class="item-tips">
              <!-- {{ detail.status == 1 ? "已购买" : "￥" + item.paperPrice }} -->
              {{ detail.status == 1 ? '已购买' : '' }}
            </div>
          </div>

          <el-button v-if="detail.status == 1 || !item.packagePrice || item.packagePrice == 0" :loading="item.loading"
            class="keep-learn" @click="goLearn(item)">
            开始答题
          </el-button>
          <div v-else class="keep-learn keep-ok">开始答题</div>
        </div>
      </div>

      <!-- 暂无课程 -->
      <div v-if="!list || list.length == 0" class="nothing">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text"><!-- {{ detail.status }} -->暂无内容哦~</div>
      </div>
    </div>
    <div v-if="detail.status != 1" class="oreder-box">
      <div>
        <div class="box-title">{{ detail.name }}</div>
        <div class="box-tips">共{{ detail.SubjectNum }}套试卷</div>
      </div>
      <div class="dis_flex">
        <div class="price">
          <span class="icon">￥</span>
          {{ detail.entrantsPrice ? detail.entrantsPrice : 0 }}
        </div>
        <div class="order-bit" @click="goOrder()">立即购买</div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { AnswerClient } from '@/api/task/answerClient'
import { getInfo } from '@/api/cookies'
import { getClass } from '@/api/home.js'
const answerClient = new AnswerClient()
import { OrderClient } from '@/api/orderClient'
const orderClient = new OrderClient()
import { getDomain } from '@/api/cookies'
export default {
  components: {},
  data() {
    return {
      packId: '',
      detail: {}, // 试卷包详情
      list: [],
      userInfo: null,
      IsPurchase: false,
      classDetail: {},
      Loading: false,
      copyright: {} /* { name: "同行转型", key: 1 },
    { name: "招商加盟", key: 2 },
    { name: "高校合伙人", key: 3 } */
    }
  },
  created() {
    this.copyright = getDomain()
    this.packId = this.$route.query.packId ? String(this.$route.query.packId) : null
    this.classId = this.$route.query.classId ? Number(this.$route.query.classId) : null
    this.IsPurchase = eval(this.$route.query.IsPurchase)
    this.userInfo = getInfo()
    if (this.classId) {
      getClass(this.classId).then((res) => {
        this.classDetail = res.data.BX
      })
    }
    this.getDetail()
  },
  methods: {
    // 获取试卷包列表
    getDetail() {
      answerClient
        .getPackageDetails(
          this.packId,
          this.userInfo ? this.userInfo.id : undefined,
          this.classId ? this.classId : undefined
        )
        .then((res) => {
          this.detail = res.data.paper

          this.detail.entrantsPrice =
            res.data?.product?.entrantsPrice || res.data?.product?.entrantsPrice == 0
              ? res.data.product.entrantsPrice
              : res.data.paper.originalPrice
          this.detail.SubjectNum = res.data.paper.paperNameList ? res.data.paper.paperNameList.length : 0
          this.list = res.data.paper.paperNameList.map(item => {
            this.$set(item, 'loading', false)
            return item
          })
          if (this.IsPurchase) {
            this.detail.status = 1
          } else {
            this.IsPurchase = this.detail.status == 1
          }
        })
      this.$forceUpdate()
    },

    /* 做题 */
    goLearn(item) {
      item.loading = true
      answerClient.selectPaperSubjectList(item.id).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExam(
            item.name,
            item.id,
            4,
            6,
            this.IsPurchase,
            res.data.paper.seeNum,
            this.packId,
            undefined,
            this.classId ? this.classId : undefined
          )
        }
        item.loading = false
      })
    },
    // 立即购买
    goOrder() {

      if (this.userInfo) {
        if (this.classId) {
          orderClient
            .addOrders(
              this.classId,
              this.userInfo.id,
              this.classDetail.tiitle,
              1,
              this.classDetail.tiitle,
              this.classDetail.kind
            )
            .then((res) => {
              if (res.code == 0) {
                this.$router.push({
                  name: '个人中心订单页',
                  params: { orderNumber: res.msg }
                })
              } else {
                this.$message.error(res.msg)
              }
            })
        } else {
          orderClient
            .addOrders(
              this.detail.id,
              this.userInfo.id,
              this.detail.name,
              6,
              this.detail.name,
              this.detail.kindId
            )
            .then((res) => {
              if (res.code == 0) {
                this.$router.push({
                  name: '个人中心订单页',
                  params: { orderNumber: res.msg }
                })
              } else {
                this.$message.error(res.msg)
              }
            })
        }
      } else {
        Vue.prototype.goLoginView(true)
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    }
  }
}
</script>
<style lang="less" scoped>
.pack {
  width: 1220px;
  margin: 0px auto;
  padding-bottom: 120px;

  .back {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
    margin: 20px 0px;
    cursor: pointer;

    .iconfont {
      margin-right: 8px;
      transform: rotate(180deg);
      display: inline-block;
    }
  }

  .list {
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding: 0px 32px 32px;
  }

  .item {
    position: relative;
    padding: 32px 0px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;

    .item-image {
      width: 60px;
      height: 80px;
      flex-shrink: 0;
      background: #f7f8f9;
      margin-right: 12px;
    }

    .item-content {
      height: 80px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .item-title {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        width: 950px;
      }
    }

    .keep-learn {
      flex-shrink: 0;
    }
  }
}

.oreder-box {
  position: absolute;
  bottom: 240px;
  width: 100%;
  height: 88px;
  background: #ffffff;
  box-shadow: 0px -4px 8px 1px rgba(204, 204, 204, 0.20000000298023224);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding: 0px calc(50% - 610px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .box-title {
    font-size: 20px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
  }

  .box-tips {
    height: 20px;
    background: #fff2e9;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    padding: 0px 8px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ff7b1c;
    line-height: 20px;
    display: inline-block;
    margin-top: 12px;
  }

  .price {
    font-size: 32px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: Bold;
    color: #ff5e51;
    margin-top: 8px;
    line-height: 32px;

    .icon {
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
    }
  }

  .order-bit {
    width: 170px;
    height: 48px;
    background: #ff5e51;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 48px;
    text-align: center;
    margin-left: 20px;
    cursor: pointer;
  }
}

.pack-box {
  position: relative;
  min-height: 80vh;

  .oreder-box {
    bottom: 0px !important;
  }
}

/* .pack-box3 {
  .oreder-box {
    bottom: 300px !important;
  }
}
.pack-box1 {
  .oreder-box {
    bottom: 440px !important;
  }
} */
</style>
